import React, {useState, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FiEye,FiEyeOff} from 'react-icons/fi';
import './login.css';
import {login} from "../../core/Services/User/login/login";
import {toast} from "react-toastify";
import loginLogo from "../../Assests/images/feature3.png"
const Login = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    username : '',
    password : ''
  })

  const changeHandler = useCallback((e) => {
    setData({
      ...data,
      [e.target.name] : e.target.value
    })
  }, [data])

  const showPasswordHandler = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const submitHandler = useCallback((e) => {
    e.preventDefault();
    login(data.username.trim(), data.password.trim()).then((res)=>{
      // console.log("AAR A HAI DATA",res.token )
      if(res){
        window.location.href="/dashboard"
        toast.success('You are successfully logged In');

      }
    })
    // console.log(data)

    // const cleanData = {  
    //   username : data.username.trim(),
    //   password : data.password.trim()
    // }

    // if(cleanData.username && cleanData.password) {
    //   navigate('dashboard')
    // }

  }, [data, navigate])

  return (
    <div className='auth-container d-grid onboarding-grids login'>
      <section className='d-flex justify-content-center align-items-center no-border'>
        <img src = {loginLogo} className="m-0" alt="login"></img>
      </section>
      <section className='d-flex flex-column justify-content-center align-items-center'>
        <h2>Login to your account.</h2>
        <form className='d-flex flex-column' onSubmit = {submitHandler}>
          <div className='input-container d-flex flex-column'>
            <label>Username</label>
            <input name = 'username' onChange={changeHandler} value  = {data.username} placeholder='Username' required/>
          </div>
          <div className='input-container d-flex flex-column'>
            <label>Password</label>
            <div className='password-input'>
            <input name = 'password' onChange={changeHandler} value  = {data.password} placeholder='Password' type={showPassword ? 'text' : 'password'} required/>
            {showPassword ? <FiEye size={16} onClick = {showPasswordHandler}/> : <FiEyeOff size={16} onClick = {showPasswordHandler} />}
            </div>
          </div>
          <Link className='text-right' to = '/forgot'>Forgot Password</Link>
          <button className='auth-button d-block m-auto'>Login</button>
          {/* <p className='text-center'>Don't have an account? <Link to = '/'>Sign Up</Link></p> */}
        </form>
      </section>
    </div>
  )
}

export default Login