import moment from "moment";
import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import InputGrp from "../../Components/common/inputGRP";
import Loading from "react-fullscreen-loading";
import { channelMannagerBooking, getAllRoomType, getRatePlan, } from "../../core/Services/Data/EaseRoom";


function BookingForm({ user, setTab, onSave }) {
  const [roomType, setRoomType] = React.useState([]);
  const [mealType, setMealType] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [State, setState] = React.useState([]);
  const [City, setCity] = React.useState([]);
  // const [CustomerID, setCustomerID] = React.useState("");
  // const idTypes = ["Aadhaar Card", "Voter ID", "Driving License"];

  const [booking, setbooking] = React.useState({
    Header: {
      Username: user.live.easeRoom.email,
      Password: user.live.easeRoom.password,
    },
    Username: "Zonzo",
    UserCode: "Zonzo",
    Checkin: "",
    Checkout: "",
    BookingAmount: "",
    AdvancePayment: "",
    BalanceLeft: "",
    BookingId: "",
    BookingStatus: "",
    BookingDate: "",
    GIRoomTypeCode: "",
    GuestName: "",
    TotalRooms: "",
    AdultCount: "",
    ChildCount: "0",
    GIHotelID: user?.live?.easeRoom?.secret,
    OTAupdate: "false",
    Meal: "",
    Phone: "",
    Email: "",
    // IdType: "",
    // IdNumber: "",
    // CustomerID: "",
    BookingSource: "",
    SpecialNote: "",
    Countries: "India",
    City: [],
    State: "",
  });

  const navigate = useNavigate();

  const fetchAccessToken = async () => {
    try {
      const response = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "api-token": process.env.REACT_APP_API_TOKEN,
          "user-email": process.env.REACT_APP_USER_EMAIL
        }
      });
      const data = await response.json();
      return data.auth_token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };


  const fetchStateAndCities = async (selectedState) => {
    try {
      const accessToken = await fetchAccessToken();
      const cityResponse = await fetch(`https://www.universal-tutorial.com/api/cities/${selectedState}`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + accessToken,
        }
      });
      if (!cityResponse.ok) {
        throw new Error(`HTTP error! Status: ${cityResponse.status}`);
      }
      const cityData = await cityResponse.json();

      setCity(cityData);
      setbooking(prevBooking => ({ ...prevBooking, City: cityData }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchState = async () => {
    try {
      const accessToken = await fetchAccessToken();
      const stateResponse = await fetch(`https://www.universal-tutorial.com/api/states/India`, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": "Bearer " + accessToken,
        }
      });
      if (!stateResponse.ok) {
        throw new Error(`HTTP error! Status: ${stateResponse.status}`);
      }
      const stateData = await stateResponse.json();


      setState(stateData);


      await fetchStateAndCities(stateData[0].state_name);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchState();
  }, []);

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setbooking(prevBooking => ({ ...prevBooking, State: selectedState }));
    await fetchStateAndCities(selectedState);
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("bookingFormData"));

    if (storedData) {
      setbooking(storedData);
    }

  }, []);


  const handleReset = () => {
    setbooking({
      Header: {
        Username: user.live.easeRoom.email,
        Password: user.live.easeRoom.password,
      },
      Username: "Zonzo",
      UserCode: "Zonzo",
      Checkin: "",
      Checkout: "",
      BookingAmount: "",
      AdvancePayment: "",
      BalanceLeft: "",
      BookingId: "",
      BookingStatus: "",
      BookingDate: "",
      GIRoomTypeCode: "",
      GuestName: "",
      TotalRooms: "",
      AdultCount: "",
      ChildCount: "0",
      GIHotelID: user?.live?.easeRoom?.secret,
      OTAupdate: false,
      Meal: "",
      Phone: "",
      Email: "",
      // IdNumber: "",
      // IdType: "",
      // customerID: "",
      BookingSource: "",
      SpecialNote: "",
      Countries: "India",
      City: [],
      States: "",
    });
    setCity([]);
    setErrors({});
    // setCustomerID(null);
    // document.getElementById("customerID").value = '';

    localStorage.removeItem("bookingFormData");

  };

  //submit form
  const SubmitEvent = async (e) => {
    e.preventDefault();

    let parts = booking.GIRoomTypeCode.split("-");
    let roomName = parts[1];
    let roomCode = parts[0].trim();

    booking.GIRoomTypeCode = roomCode;
    booking.RoomTypeName = roomName;

    localStorage.setItem("bookingFormData", JSON.stringify(booking));
    setLoading(true);

    channelMannagerBooking(booking)
      .then(res => {
        console.log("Response: ", res);

        navigate("/bookings/Allbookings");
        toast.success("Successfully Booked");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);

        setbooking({
          Header: {
            Username: user.live.easeRoom.email,
            Password: user.live.easeRoom.password,
          },
          Username: "Zonzo",
          UserCode: "Zonzo",
          Checkin: "",
          Checkout: "",
          BookingAmount: "",
          AdvancePayment: "",
          BalanceLeft: "",
          BookingId: "",
          BookingStatus: "",
          BookingDate: "",
          GIRoomTypeCode: "",
          GuestName: "",
          TotalRooms: "",
          AdultCount: "",
          ChildCount: "0",
          GIHotelID: user?.live?.easeRoom?.secret,
          OTAupdate: "",
          Meal: "",
          Phone: "",
          Email: "",
          // IdNumber: "",
          // IdType: "",
          // CustomerID: null,
          BookingSource: "",
          SpecialNote: "",
          Countries: "India",
          City: [],
          State: "",
        });

        localStorage.removeItem("bookingFormData");
      });
  };

  //for customerID
  // const handleCustomerID = (e) => {
  //   e.preventDefault();

  //   const { currentTarget: input } = e;

  //   const file = e.target.files[0];

  //   if (input.name === "customerID") {
  //     setCustomerID(input.value);
  //   }

  //   if (file) {
  //     const fileSize = file.size;
  //     const maxSize = 1024 * 1024;

  //     if (fileSize > maxSize) {
  //       toast.error("File size exceeds 1MB. Please upload a smaller file.");
  //       setCustomerID(null);
  //       document.getElementById("customerID").value = ''; // Reset the file input value

  //       return;
  //     }

  //     // setCustomerID(file)
  //   }
  // };

  const handleChange = (e) => {
    e.preventDefault();

    const { currentTarget: input } = e;
    const demobooking = { ...booking };

    if (input.name === "OTAupdate") {
      demobooking[input.name] = input.checked;
    } else {
      demobooking[input.name] = input.value;
    }

    const selectedState = e.target.value;
    setbooking(prevBooking => ({ ...prevBooking, States: selectedState }));


    if (demobooking["AdvancePayment"] !== "") {
      const balanceLeft =
        demobooking["BookingAmount"] - demobooking["AdvancePayment"];
      demobooking["BalanceLeft"] = balanceLeft;
    } else {
      demobooking["BalanceLeft"] = demobooking["BookingAmount"];
    }

    setbooking({ ...demobooking });
    localStorage.setItem("bookingFormData", JSON.stringify(demobooking));
  };

  useEffect(() => {
    const payload = {
      Header: {
        Username: user.live.easeRoom.email,
        Password: user.live.easeRoom.password,
      },
      HotelId: user.live.easeRoom.secret,
    };
    setLoading(true);

    let gettingAllRoomType = () => {
      getAllRoomType(payload)
        .then(({ data }) => {
          const arr = [];
          data.EaseRoomType.map((ele) => {
            arr.push({ id: ele.RoomTypeId, name: ele.RoomTypeName });
          });
          setRoomType([...arr]);
          setLoading(false);
        })
        .catch((e) => {
          gettingAllRoomType()
        })
    }
    gettingAllRoomType()
    setLoading(true);

    let gettingRatePlan = () => {
      getRatePlan(payload)
        .then(({ data }) => {
          var nameOccurrences = {};
          var uniqueObjects = data?.RatePlan.filter(function (obj) {
            if (!nameOccurrences[obj.RateplanName]) {
              nameOccurrences[obj.RateplanName] = true;
              return true;
            }
            return false;
          });

          const meal = [];
          uniqueObjects.map((ele) => { meal.push({ id: ele.RateplanID, name: ele.RateplanName }); });

          setMealType([...meal]);
          setLoading(false);
        })
        .catch((e) => {
          gettingRatePlan()
        })
    }
    gettingRatePlan()
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#370D6F" />
      <div className="bg-[#F9F9F9]   ">
        <form className="w-full bg-[#ffffff] shadow-lg px-6 m-auto text-xl pb-4">
          <div>
            <h3 class="mb-2 text-center font-bold text-indigo-900 text-2xl tracking-wide">
              Booking Form
            </h3>
          </div>
          <div class="my-5">
            <fieldset class=" grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2 shadow-md">
              <legend class="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Booking Details</h3>
              </legend>
              <InputGrp
                label="Check In"
                name="Checkin"
                placeHolder="Check In"
                type="date"
                disabled={true}
                value={booking.Checkin}
                minDate={moment(new Date()).format("YYYY-MM-DD")}
                setData={handleChange}
              />
              <InputGrp
                label="Check Out"
                name="Checkout"
                placeHolder="Check Out"
                type="date"
                disabled={true}
                value={booking.Checkout}
                minDate={moment(new Date()).format("YYYY-MM-DD")}
                setData={handleChange}
              />

              <InputGrp
                label="Booking Date"
                name="BookingDate"
                placeHolder="Booking Date"
                type="date"
                value={booking.BookingDate}
                setData={handleChange}
              />
              <InputGrp
                label="Booking Id"
                name="BookingId"
                placeHolder="Booking Id"
                type="text"
                value={booking.BookingId}
                setData={handleChange}
              />
              <InputGrp
                label="Booking Source"
                name="BookingSource"
                placeHolder="Booking Source"
                type="text"
                value={booking.BookingSource}
                setData={handleChange}
              />
              <InputGrp
                label="Country"
                name="Countries"
                value={booking.Countries}
                setData={handleChange}
              />

              <div className={`text-start w-[100%] m-4`}>
                <label className="block text-sm font-bold text-[#370D6F]">
                  {"State"}
                </label>

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="State"
                  id="State"
                  value={booking.State}
                  onChange={(e) => { handleStateChange(e); }}
                >
                  <option value={null} selected>
                    Select
                  </option>

                  {State.map(state => (
                    <option value={state.state_name}>
                      {state.state_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className={`text-start w-[100%] m-4`}>
                <label className="block text-sm font-bold text-[#370D6F]">
                  {"City"}
                </label>

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="City"
                  id="City"
                  value={booking.City}
                  onChange={(e) => { handleChange(e); }}
                >

                  <option value={null} selected>
                    Select
                  </option>

                  {City.map((city, index) => (
                    <option key={index} value={city.city_name}>
                      {city.city_name}
                    </option>
                  ))}

                </select>
              </div>

            </fieldset>
          </div>

          <div class="mb-5">
            <fieldset class=" grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2 shadow-md">
              <legend class="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Guest Details</h3>
              </legend>
              <InputGrp
                label="Guest Name"
                name="GuestName"
                placeHolder="Guest Name"
                type="GuestName"
                value={booking.GuestName}
                setData={handleChange}
              />
              <InputGrp
                label="Adult Count"
                name="AdultCount"
                placeHolder="Adult Count"
                type="text"
                value={booking.AdultCount}
                setData={handleChange}
              />
              <InputGrp
                label="Child Count"
                name="ChildCount"
                placeHolder="0"
                type="text"
                value={booking.ChildCount}
                setData={handleChange}
              />
              <InputGrp
                label="Contact Number"
                name="Phone"
                placeHolder="Contact Number"
                maxLength="12"
                type="number"
                value={booking.Phone}
                setData={handleChange}
              />
              <InputGrp
                label="Email"
                name="Email"
                placeHolder="Email Address"
                type="email"
                value={booking.Email}
                setData={handleChange}
              />

              {/* <div className={`text-start w-[100%] m-4`}>
                <label className="block text-sm font-bold text-[#370D6F]">
                  {"CustomerID Type"}
                </label>

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="IdType"
                  id="IdType"
                  value={booking.IdType}
                  onChange={(e) => { handleChange(e); }}
                >
                  <option value="">
                    Select
                  </option>

                  {idTypes.map((idType) => (
                    <option key={idType} value={idType}>
                      {idType}
                    </option>
                  ))}
                </select>
              </div> */}

              {/* <InputGrp
                label="CustomerID Number"
                name="IdNumber"
                placeHolder="Enter CustomerID Number"
                type="text"
                value={booking.IdNumber}
                setData={handleChange}
              /> */}

              {/* <InputGrp
                label="Customer ID"
                name="customerID"
                placeHolder="Upload Customer ID here (File size should be less than 1MB )"
                type="file"
                value={CustomerID}
                setData={handleCustomerID}
              /> */}


              <div class="flex flex-col m-4 col-span-2 w-full">
                <label className="text-[#370D6F] font-bold text-sm" htmlFor="Special-note">
                  Special Note
                </label>
                <textarea className="text-sm border border-solid border-slate-300 rounded-md p-2 mt-2 w-full outline-none "
                  rows="1"
                  name="SpecialNote"
                  placeHolder="Special Note"
                  type="text"
                  value={booking.SpecialNote}
                  onChange={handleChange}
                />
              </div>
            </fieldset>
          </div>

          <div class="my-5">
            <fieldset className="grid grid-cols-4 gap-x-10 border border-solid border-indigo-600 rounded-md pr-8 py-2  shadow-md">
              <legend className="ml-9 bg-[#9A48D0] rounded-md px-4 py-1 text-white shadow-md">
                <h3 class="text-base tracking-wide">Room & Payment Details</h3>
              </legend>
              <div className={`text-start mt-2 w-[100%] p-4`}>
                {
                  <label
                    htmlFor={"roomType"}
                    className="block text-sm font-bold text-[#370D6F]"
                  >
                    {"RoomType"}
                  </label>
                }

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="GIRoomTypeCode"
                  id="roomType"
                  value={booking.GIRoomTypeCode}
                  onChange={(e) => { handleChange(e); }}
                >
                  <option value={null} selected>
                    Select
                  </option>
                  {roomType &&
                    roomType.map((ele) => (
                      <option key={ele.id} value={ele.id + "-" + ele.name}>
                        {ele.name}
                      </option>
                    ))}
                </select>
                {/* <p className="text-red-900 text-sm">{errors}</p> */}

              </div>

              <InputGrp
                label="Total Rooms"
                name="TotalRooms"
                placeHolder="Total Rooms"
                type="text"
                value={booking.TotalRooms}
                setData={handleChange}
              />

              <div className={`text-start w-full mt-5 `}>
                {
                  <label htmlFor={"roomType"} className="block text-sm font-bold text-[#370D6F]">
                    {"Meal plan"}
                  </label>
                }

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="Meal"
                  id="roomType"
                  value={booking.Meal}
                  onChange={(e) => { handleChange(e); }}
                >
                  <option value={null} selected>
                    Select
                  </option>
                  {mealType &&
                    mealType.map((ele) => (
                      <option value={ele.name}>{ele.name}</option>
                    ))}
                </select>
                {/* <p className="text-red-900 text-sm">{errors}</p> */}
              </div>

              <InputGrp
                label="GI Hotel ID"
                name="GIHotelID"
                placeHolder="GI Hotel ID"
                type="text"
                value={user.live.easeRoom.secret}
              // setData={handleChange}
              />

              <InputGrp
                label="Booking Amount"
                name="BookingAmount"
                placeHolder="Booking Amount"
                type="number"
                value={booking.BookingAmount}
                setData={handleChange}
              />

              <InputGrp
                label="Advance Payment"
                name="AdvancePayment"
                placeHolder="Advance Payment"
                type="number"
                value={booking.AdvancePayment}
                setData={handleChange}
              />

              <InputGrp
                label="Balance Left"
                name="BalanceLeft"
                placeHolder="Balance Left"
                type="number"
                value={booking.BalanceLeft}
                setData={handleChange}
              />

              <div className={`text-start w-full m-4 `}>
                {
                  <label htmlFor={"bookingStatus"} className="block text-sm font-bold text-[#370D6F]">
                    {"Booking Status"}
                  </label>
                }

                <select className="border border-slate-300 border-solid rounded-md mt-2 w-[100%] text-sm p-2 "
                  name="BookingStatus"
                  id="bookingStatus"
                  value={booking.BookingStatus}
                  onChange={(e) => { handleChange(e); }}
                >
                  <option value={null}>Select</option>
                  <option value="Booked">Confirmed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
                {/* <p className="text-red-900 text-sm">{errors}</p> */}
              </div>

              <div className={`text-center w-full m-4 col-end-5`}>
                { }
                <input
                  className="m-2 mr-6 "
                  name="OTAupdate"
                  id="otaupdate"
                  type="checkbox"
                  checked={booking.OTAupdate}
                  onChange={(e) => { handleChange(e); }}
                >
                </input>

                <label htmlFor={"otaupdate"} className=" text-sm font-bold text-[#370D6F]">
                  {"OTA Update"}
                </label>
              </div>
            </fieldset>
          </div>

          <div class="text-center pt-1 mb-5 pb-1">
            <button className=" mb-3 mr-5 px-5 py-1 bg-[#8F00FF] text-[#ffffff] text-md rounded-lg shadow-lg hover:bg-[#B660CD] duration-200 ease-in"
              type="button"
              onClick={(e) => SubmitEvent(e)}
            >
              Create
            </button>
            <button className=" mb-3 px-5 py-1 bg-[#8F00FF] text-[#ffffff] text-md rounded-lg shadow-lg hover:bg-[#B660CD] duration-200 ease-in"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default BookingForm;