import React, { useCallback, useEffect, useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import { BsCalendarDate } from "react-icons/bs";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Toggledays from "../../../shared/components/Toggledays/Toggledays";
import {
  getAllRoomType,
  UpdateInventory,
} from "../../../core/Services/Data/EaseRoom";
import { toast } from "react-toastify";

const BulkInventory = ({ user }) => {
  const navigate = useNavigate();
  const [roomTypes, setRoomTypes] = useState([]);
  const [inventoryValues, setInventoryValues] = useState({});
  const dateDropdownRef = useRef(null);

  const [workingDays, setWorkingDays] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [workingDaysError, setWorkingDaysError] = useState(false);
  console.log("working", workingDays);
  const getWorkingDays = (days) => {
    setWorkingDays((prevState) => {
      if (days.length === 0) {
        setWorkingDaysError((prevState) => {
          return true;
        });
      } else {
        setWorkingDaysError((prevState) => {
          return false;
        });
      }
      return days;
    });
  };

  const [filters, setFilters] = useState({
    startDate: new Date(),
    endDate: new Date(),
    roomType: 1,
    reportType: 1,
  });

  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const [openRoomTypeDropdown, setOpenRoomTypeDropdown] = useState(false);
  const [openReportTypeDropdown, setOpenReportTypeDropdown] = useState(false);

  const dateRangeHandler = useCallback(
    (event) => {
      setFilters({
        ...filters,
        startDate: event.selection.startDate,
        endDate: event.selection.endDate,
      });
      // setOpenDateDropdown(false)
    },
    [filters]
  );
  //outside =====
  const handleOutsideClick = useCallback((event) => {
    if (
      dateRangeContainerRef.current &&
      !dateRangeContainerRef.current.contains(event.target)
    ) {
      // Clicked outside the date range picker, update state or perform actions
      console.log("Clicked outside");
      setOpenDateDropdown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);
  //================

  const dateDropdownHandler = useCallback(() => {
    setOpenDateDropdown(!openDateDropdown);
    setOpenRoomTypeDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openDateDropdown]);

  const roomTypeDropdownHandler = useCallback(() => {
    setOpenRoomTypeDropdown(!openRoomTypeDropdown);
    setOpenDateDropdown(false);
    setOpenReportTypeDropdown(false);
  }, [openRoomTypeDropdown]);

  const reportTypeDropdownHandler = useCallback(() => {
    setOpenReportTypeDropdown(!openReportTypeDropdown);
    setOpenDateDropdown(false);
    setOpenRoomTypeDropdown(false);
  }, [openReportTypeDropdown]);

  const handleInputChange = (roomId, value) => {
    setInventoryValues((prevValues) => ({
      ...prevValues,
      [roomId]: value,
    }));
  };
  // console.log("inv", inventoryValues)

  const handleSumbit = (e) => {
    e.preventDefault();

    let { startDate, endDate } = filters;
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    const date1 = moment(startDate).format("DD/MM/YYYY");
    const date2 = moment(endDate).format("DD/MM/YYYY");

    const payloadArray = Object.entries(inventoryValues)
      .filter(([roomId, value]) => value > 0)
      .map(([roomId, value]) => ({
        Header: {
          Username: user?.live?.easeRoom?.email,
          Password: user?.live?.easeRoom?.password,
        },
        HotelId: user?.live?.easeRoom?.secret,
        RoomTypeId: roomId,
        InfoDays: `${date1}|${date2}|${value}|${workingDays}`,
      }));
    if (!workingDays) {
      return toast.error("please select working days");
    }
    console.log("payload Array", payloadArray);
    // payloadArray.map(async (payload) => {
    //   await UpdateInventory(payload).then((res) => {
    //     console.log("res", res);
    //   });
    // });
    Promise.all(
      payloadArray.map((payload, index)=>{
      console.log("payload", payload)
    }))
    Promise.all(
      payloadArray.map(async(payload, index) => {
        
        await UpdateInventory(payload);
        // setTimeout(async () => {
        //   console.log("payload", payload)
        // }, index * 300);
      })
    )
    //   .then((resp) => {
    //     console.log("Response:", resp);
    //   })
    //   .catch((err) => {
    //     toast.error(err?.response?.data?.message);
    //     // console.log(err.response.data.message);
    //   });

    // toast.dismiss();
    // toast.success("Updated Successfully");
    // setTimeout(() => {
    //   navigate("/rates-inventory");
    // }, 500);
  };

  useEffect(() => {
    const payload = {
      Header: {
        Username: user?.live?.easeRoom?.email,
        Password: user?.live?.easeRoom?.password,
      },
      HotelId: user?.live?.easeRoom?.secret,
    };

    getAllRoomType(payload).then(({ data }) => {
      setRoomTypes(data.EaseRoomType);
    });
  }, []);

  const dateRangeContainerRef = useRef(null);

  return (
    <>
      <section className="card br-8 filters-section">
        <div className="filters">
          <div className="date filter">
            <label>Select Date*</label>
            <div
              className={openDateDropdown ? "dropdown active" : "dropdown"}
              onClick={() => setOpenDateDropdown(true)}
              ref={dateRangeContainerRef}
              style={{ cursor: "pointer" }}
            >
              <p>
                <span>
                  {moment(filters.startDate).format("DD MMMM Y")} -{" "}
                  {moment(filters.endDate).format("DD MMMM Y")}
                </span>
                <button>
                  <BsCalendarDate size={16} />
                </button>
              </p>

              <DateRangePicker
                className="date-range"
                ranges={[{ ...filters, key: "selection" }]}
                onChange={dateRangeHandler}
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="parent-container">
            <div className="w-[70%] mt-5">
              {workingDays && (
                <Toggledays
                  handleWorkingDays={getWorkingDays}
                  workingDays={workingDays}
                />
              )}
            </div>
            <div className="bulk-submit ">
              <button className="bulk-btn" onClick={handleSumbit}>
                Submit Inventories
              </button>
            </div>
          </div>
        </div>

        <div className="updatein">
          <h3>Update Inventories</h3>
        </div>
        <div className="room-types-row">
          {roomTypes &&
            roomTypes.map((el, i) => (
              <div className="invents-div" key={i}>
                <h1 className="room-type-name">{el.RoomTypeName}</h1>
                <div className="invents-input">
                  <input
                    type="number"
                    className="room-input"
                    placeholder="Number of Rooms"
                    onChange={(e) =>
                      handleInputChange(el.RoomTypeId, e.target.value)
                    }
                  />
                </div>
              </div>
            ))}
        </div>

        <hr className="mt-5" />
      </section>
      {"" + filters.startDate !== "" + filters.endDate && (
        <section className="card br-8"></section>
      )}
    </>
  );
};

export default BulkInventory;
