import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import { AiFillHome, AiFillStar, AiFillHeart } from "react-icons/ai";
import { BsFillStopCircleFill } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import { MdAnalytics } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { closeSidebarRequest, openSidebarRequest } from "../../Actions/sidebar";
import "./sidebar.css";

import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies()

const Sidebar = ({ isActive, closeSidebar, openSidebar, currentTabIndex }) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("here");
    console.log(cookies.get("token"));
    if (cookies.get("token") === null) {
      navigate("/");
      // window.location.href = "/";
    }
  }, []);

  const closeSidebarHandler = useCallback(() => {
    closeSidebar();
  }, [closeSidebar]);

  const openSidebarHandler = useCallback(() => {
    openSidebar();
  }, [openSidebar]);

  return (
    <>
      {path !== "/" &&
        path !== "/ota" &&
        path !== "/ota/verification-success" &&
        path !== "/forgot" &&
        path !== "/reset" &&
        path !== "/password-Change" &&
        path !== "/done" && (
          <aside
            onMouseLeave={() => {
              closeSidebarHandler();
            }}
            onMouseEnter={() => {
              openSidebarHandler();
            }}
           
            className={isActive ? "Sidebar active " : "Sidebar"}
          >
            <button
              className="close-button"
              type="button"
              onClick={closeSidebarHandler}
            >
              <FaTimes size={20} />
            </button>
           
            <div className="logo full ">
              <img src="/Assets/Images/Sidebar/zonzologow4.png" class="logo_img h-24 w-183" alt="" />
            </div>
            <div className="logo">
              <img src="/Assets/Images/Sidebar/zonzologow1.png" class="logo_icon" alt="" /> 
            </div>
            <ul>
              <li>
                <Link to="/dashboard">
                  <button
                    className={path.includes('/dashboard') ? "active" : ""}
                    type="button"
                  >
                    <AiFillHome size={24} />
                    <span>Dashboard</span>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/bookings/Allbookings">
                  <button
                    className={path.includes('/bookings') ? "active" : ""}
                    type="button"
                  >
                    <AiFillStar size={24} />
                    <span>Bookings</span>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/rates-inventory">
                  <button
                    className={path.includes('/rates-inventory') ? "active" : ""}
                    type="button"
                  >
                    <BsFillStopCircleFill size={24} />
                    <span >Rates & Inventory</span>
                  </button>
                </Link>
              </li>
              {/* <li>
              <Link to="/propertyDetails/Allrooms">
                <button className={currentTabIndex === 3 ? "active":""}  type="button">
                  <RiHomeHeartFill size={24} />
                  <span>Property Details</span>
                </button>
              </Link>
            </li> */}
              <li>
                <Link to="/reports/inventory">
                  <button
                    className={path.includes('/reports/inventory') ? "active" : ""}
                    type="button"
                  >
                    <HiDocumentText size={24} />
                    <span>Reports</span>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/Analytics">
                  <button
                    className={path.includes('/Analytics') ? "active" : ""}
                    type="button"
                  >
                    <MdAnalytics size={24} />
                    <span>Analytics</span>
                  </button>
                </Link>
              </li>
              {/* <li>
              <Link to="/dashboard">
                <button className={currentTabIndex === 6 ? "active":""}  type="button">
                  <AiFillHeart size={24} />
                  <span>Reviews</span>
                </button>
              </Link>
            </li> */}
              <li>
                <Link to="/Support/active">
                  <button
                    className={path.includes('/Support') ? "active" : ""}
                    type="button"
                  >
                    <BiSupport size={24} />
                    <span>Zonzo Support</span>
                  </button>
                </Link>
              </li>
            </ul>
          </aside>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isActive: state.sidebar,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeSidebar: closeSidebarRequest,
      openSidebar: openSidebarRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
